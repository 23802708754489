import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { EditorPresetBuilder } from '@atlaskit/editor-common/preset';
import { type Providers } from '@atlaskit/editor-common/provider-factory';
import { analyticsPlugin } from '@atlaskit/editor-plugins/analytics';
import { basePlugin } from '@atlaskit/editor-plugins/base';
import { betterTypeHistoryPlugin } from '@atlaskit/editor-plugins/better-type-history';
import { blockTypePlugin } from '@atlaskit/editor-plugins/block-type';
import { cardPlugin } from '@atlaskit/editor-plugins/card';
import { clearMarksOnEmptyDocPlugin } from '@atlaskit/editor-plugins/clear-marks-on-empty-doc';
import { clipboardPlugin } from '@atlaskit/editor-plugins/clipboard';
import { codeBlockPlugin } from '@atlaskit/editor-plugins/code-block';
import { compositionPlugin } from '@atlaskit/editor-plugins/composition';
import { copyButtonPlugin } from '@atlaskit/editor-plugins/copy-button';
import { decorationsPlugin } from '@atlaskit/editor-plugins/decorations';
import { editorDisabledPlugin } from '@atlaskit/editor-plugins/editor-disabled';
import { floatingToolbarPlugin } from '@atlaskit/editor-plugins/floating-toolbar';
import { focusPlugin } from '@atlaskit/editor-plugins/focus';
import { gridPlugin } from '@atlaskit/editor-plugins/grid';
import { historyPlugin } from '@atlaskit/editor-plugins/history';
import { hyperlinkPlugin } from '@atlaskit/editor-plugins/hyperlink';
import { listPlugin } from '@atlaskit/editor-plugins/list';
import { mentionsPlugin } from '@atlaskit/editor-plugins/mentions';
import { pastePlugin } from '@atlaskit/editor-plugins/paste';
import {
	placeholderPlugin,
	type PlaceholderPluginOptions,
} from '@atlaskit/editor-plugins/placeholder';
import { quickInsertPlugin } from '@atlaskit/editor-plugins/quick-insert';
import { saveOnEnterPlugin } from '@atlaskit/editor-plugins/save-on-enter';
import { selectionPlugin } from '@atlaskit/editor-plugins/selection';
import { typeAheadPlugin } from '@atlaskit/editor-plugins/type-ahead';
import { undoRedoPlugin } from '@atlaskit/editor-plugins/undo-redo';
import { widthPlugin } from '@atlaskit/editor-plugins/width';
import type { useLinkPickerEditorProps } from '@atlassian/link-picker-plugins/editor';

export type AIPromptPresetOptions = {
	placeholder: PlaceholderPluginOptions;
	onSave?: () => void;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	allowAnalyticsGASV3?: boolean;
	linkPicker?: ReturnType<typeof useLinkPickerEditorProps>;
	cardProvider?: Providers['cardProvider'];
	mentionProvider?: Providers['mentionProvider'];
};

export function createAIPromptPreset({
	placeholder,
	onSave,
	createAnalyticsEvent,
	allowAnalyticsGASV3,
	linkPicker,
	cardProvider,
	mentionProvider,
}: AIPromptPresetOptions) {
	const preset = new EditorPresetBuilder()
		// Most of analytics events in editor are fired by appending
		//  AnalyticsStep in transaction.
		// Without this plugin, none of those events will be fired.
		// If allowAnalyticsGASV3 is truthy, then analytics plugin will be added.
		.maybeAdd(
			[
				analyticsPlugin,
				{
					createAnalyticsEvent,
				},
			],
			Boolean(allowAnalyticsGASV3),
		)
		// Dependency of paste plugin
		.add(betterTypeHistoryPlugin)
		// we need paste plugin to handle paste event.
		.add([pastePlugin, {}])
		// We need clipboard to handle cut and copy and clipboard serialization.
		.add(clipboardPlugin)
		// Handles focus and blur events.
		.add(focusPlugin)
		// This plugin handles composition events and take care nuances of composition.
		// Need this plugin to have sleek composition support for languages such as Japanese, Chinese
		.add(compositionPlugin)
		// Editor can't function without this plugin.
		// It takes care of doc, paragraph and text nodes, backbone of any document.
		.add([basePlugin, {}])
		// Dependency of cardPlugin, floatingToolbarPlugin
		.add(decorationsPlugin)
		// Depdency of undoRedoButtons
		.add(typeAheadPlugin)
		// Depdency of undoRedoButtons
		.maybeAdd(historyPlugin, true)
		// We want to support undo/redo through shortcuts.
		.maybeAdd(undoRedoPlugin, true)
		// We should keep this plugin. It will remove all the marks when doc is empty.
		// It's very lightweight plugin.
		.add(clearMarksOnEmptyDocPlugin)
		// Dependency of cardPlugin
		.maybeAdd([hyperlinkPlugin, { linkPicker }], !!linkPicker)
		.maybeAdd([mentionsPlugin], !!mentionProvider)
		// Dependency of cardPlugin, gridPlugin
		.add(widthPlugin)
		// Need placeholder plugin to show placeholder
		.add([placeholderPlugin, placeholder])
		// Dependency of floatingToolbarPlugin
		.add(editorDisabledPlugin)
		// Dependency of floatingToolbarPlugin
		.add(copyButtonPlugin)
		// Dependency of cardPlugin
		.add(floatingToolbarPlugin)
		// Need selections plugin to handle selection nuances in editor, support gap cursor and keyboard handling for selection.
		.add([selectionPlugin, {}])
		// Need to have save on enter functionality. Without this plugin, on enter editor will add line break and then prompt is submited.
		.add([saveOnEnterPlugin, onSave])
		// Dependency of cardPlugin
		.maybeAdd([gridPlugin, {}], true)
		// Need card plugin to show smart links.
		.maybeAdd(
			[
				cardPlugin,
				{
					provider: cardProvider,
					linkPicker,
					disableFloatingToolbar: false,
					showUpgradeDiscoverability: false,
					allowBlockCards: false,
					allowEmbeds: false,
				},
			],
			!!cardProvider,
		)
		// Need this plugin for adding new line on `Shift + Enter` keymap
		.add([blockTypePlugin, { allowBlockType: { exclude: ['heading'] } }])
		.add([quickInsertPlugin, {}])
		.add([codeBlockPlugin, {}])
		.add(listPlugin);
	return preset;
}
